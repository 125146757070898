 /**
 * Este archivo incluye los polyfills necesarios para Angular y se carga antes de la aplicación.
 * Puede agregar sus propios polyfills adicionales a este archivo.
 *
 * Este archivo está dividido en 2 secciones:
 * 1. Polyfills del navegador. Estos se aplican antes de cargar ZoneJS y se ordenan por navegadores.
 * 2. Importaciones de aplicaciones. Archivos importados después de ZoneJS que deben cargarse antes de su principal
 *      archivo.
 *
 * La configuración actual es para los llamados navegadores "evergreen"; las últimas versiones de navegadores que
 * se actualizan automáticamente. Esto incluye Safari >= 10, Chrome >= 55 (incluyendo Opera),
 * Edge >= 13 en el escritorio e iOS 10 y Chrome en el móvil.
 *
 * Obtenga más información en https://angular.io/guide/browser-support
 */

/**************************************************** ****************************************************
 * POLYRELLENOS DEL NAVEGADOR
 */

/** IE10 e IE11 requieren lo siguiente para la compatibilidad con NgClass en elementos SVG */
// importar 'lista de clases.js'; // Ejecute `npm install --save classlist.js`.

/**
 * Animaciones web `@angular/platform-browser/animations`
 * Solo se requiere si se usa AnimationBuilder dentro de la aplicación y se usa IE/Edge o Safari.
 * El soporte de animación estándar en Angular NO requiere ningún relleno múltiple (a partir de Angular 6.0).
 */
// importar 'web-animaciones-js'; // Ejecute `npm install --save web-animations-js`.

/**
 * De forma predeterminada, zone.js parcheará todas las macroTask y DomEvents posibles
 * el usuario puede deshabilitar partes del parche macroTask/DomEvents configurando las siguientes banderas
 * porque esos indicadores deben configurarse antes de que se cargue `zone.js`, y el paquete web
 * colocará la importación en la parte superior del paquete, por lo que el usuario debe crear un archivo separado
 * en este directorio (por ejemplo: zone-flags.ts), y coloque las siguientes banderas
 * en ese archivo y luego agregue el siguiente código antes de importar zone.js.
 * import './zone-flags';
 *
 * Las banderas permitidas en zone-flags.ts se enumeran aquí.
 *
 * Las siguientes banderas funcionarán para todos los navegadores.
 *
 * (ventana como cualquiera).__Zone_disable_requestAnimationFrame = true; // deshabilitar la solicitud de parcheAnimationFrame
 * (ventana como cualquiera).__Zone_disable_on_property = true; // deshabilite el parche en la propiedad como onclick
 * (ventana como cualquiera).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mouseemove']; // deshabilita los nombres de eventos especificados del parche
 *
 * en las herramientas de desarrollo de IE/Edge, el addEventListener también estará envuelto por zone.js
 * con el siguiente indicador, omitirá el parche `zone.js` para IE/Edge
 *
 * (ventana como cualquiera).__Zone_enable_cross_context_check = true;
 *
 */

/**************************************************** ****************************************************
 * Zone JS se requiere de forma predeterminada para Angular.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.


/***************************************************************************************************
 * APPLICATION IMPORTS
 */
